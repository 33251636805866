import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as Api from "services/API"
// material ui icons
import Close from "@material-ui/icons/Close";
import ChipInput from 'material-ui-chip-input'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import '@fortawesome/react-fontawesome'
import Editor from 'material-ui-editor'

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useSweetAlert = makeStyles(sweetAlertStyles);
export default function EmailForm(props) {
    // type validation
    const sweetAlertClasses = useSweetAlert();
    const [requiredFirstName, setrequiredFirstName] = React.useState("");
    const [requiredSubject, setrequiredSubject] = React.useState("");
    const [typeEmail, settypeEmail] = React.useState("");
    const [attachment, setAttachment] = React.useState(null);
    const [stalert, setAlert] = React.useState(null);
    const { modalRefEmail, reacTableRef } = props;
   
    React.useEffect(() => {
        let selectedEmail;
        if (reacTableRef && reacTableRef.current) {
            let totalRows=Object.keys(reacTableRef.current.selectedRows.selectedRowIds).length;
            if(totalRows >0 && totalRows <=10) {
                selectedEmail = Object.keys(reacTableRef.current.selectedFlatRows.selectedFlatRows).map((x, idx) => {
                    return reacTableRef.current.selectedFlatRows.selectedFlatRows[x].values.email
                });
                setrequiredFirstName(selectedEmail);
            }
           if(totalRows >10){
            setAlert(
                <SweetAlert
                    info
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Information!"
                    onConfirm={() => hideSaveAlert()}
                    onCancel={() => hideSaveAlert()}
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.info}
                >
                    Upto 10 members allowed for a time being!
                </SweetAlert>
            );
           }
        }

    }, [])


    let save = () => {

        const data = {
            EmailTo: requiredFirstName,
            Subject: requiredSubject,
            Body: typeEmail
        }

        let postData = Api.postData(window._ymUrl + "/api/Email/SendEmail", data).then((response) => {
            if (response.succeed) {
                if (response.value != null) {
                    setAlert(
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Email!"
                            onConfirm={() => hideSaveAlert()}
                            onCancel={() => hideSaveAlert()}
                            confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
                        >
                            Email Send successfully..!!
                        </SweetAlert>
                    );
                }
                else {
                    setAlert(
                        <SweetAlert
                            info
                            style={{ display: "block", marginTop: "-100px" }}
                            title="Information!"
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                            confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.info}
                        >
                            Member already exist..!!
                        </SweetAlert>
                    );
                }
            }
            else {
                setAlert(
                    <SweetAlert
                        warning
                        title="Error!"
                        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.warning}
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                    >
                        Something went wrong.
                    </SweetAlert>
                );
            }

            return response.value;
        }).catch((err) => {
            setAlert(
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Warning!"
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.warning}
                >
                    Something went wrong.
                </SweetAlert>
            );
        });

    }

    const send = () => {

        if (requiredFirstName === "") {
            setrequiredFirstName("error");
        }
        if (requiredSubject === "") {
            setrequiredSubject("error");
        }
        save();
    };

    let handleChange = (chips) => {
        setrequiredFirstName(chips);
    }

    const hideAlert = () => {

        setAlert(null);

    };
    const hideSaveAlert = () => {

        setAlert(null);
        if (modalRefEmail && modalRefEmail.current) {
            modalRefEmail.current.Close();
        }
    };

    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const classes = useStyles();
    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" text>
                        <CardText color="rose">
                            <h4 className={classes.cardTitle}>Email Form</h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <form>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        To
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <ChipInput
                                        value={requiredFirstName}
                                        onChange={(chips) => handleChange(chips)}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Subject
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={requiredSubject === "success"}
                                        error={requiredSubject === "error"}
                                        id="requiredSubject"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyLength(event.target.value, 0)) {
                                                    setrequiredSubject("success");
                                                } else {
                                                    setrequiredSubject("error");
                                                }
                                                setrequiredSubject(event.target.value);
                                            },
                                            type: "text",
                                            placeholder: "Please Enter Subject",
                                            reference: "Lname",
                                            endAdornment:
                                                requiredSubject === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <Editor
                                onChange={(content) => { settypeEmail(content) }} />
                        </form>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button color="rose" onClick={send}>
                            Send Email
                        </Button>
                    </CardFooter>
                </Card>
            </GridItem>
            {stalert}
        </GridContainer>

    );
}