import { useCallback, useState, useEffect } from "react";

export function useCallbackAsync(url, config) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    let loadData = useCallback(async () => {
        setLoading(true);
        await fetch(url, config)
            .then(res => {
                if (res.ok) {
                    setData(res.json());
                }
                else {
                    setLoading(false);
                }
            })
            .catch(ex => {
                setLoading(false);
            })
    }
        , [url]);
    useEffect(() => {
        loadData();
    }, [url, loadData]);

    return { loading, data };
}

export const GenericAPI = (url, config) => {
    return new Promise((resolve) => {
        fetch(url, config)
            .then(res => {
                if (res.ok) {
                    resolve(res.json())
                }
                else {
                    resolve({ success: false })
                }
            })
            .catch(ex => {
                resolve({ success: false })
            })
    })
}