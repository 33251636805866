import Dashboard from "views/Dashboard/Dashboard.js";
import Membership from "views/Membership/Membership.js";
import MembershipForm from "views/Membership/MembershipForm";
import LoginPage from "views/Pages/LoginPage.js";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from '@mui/icons-material/Group';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/membership",
    name: "Membership",
    icon: GroupIcon,
    component: Membership,
    layout: "/admin",
  },
];
export default dashRoutes;
