import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import CustomInputWithMask from "components/CustomInput/CustomInputWithMask";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as Api from '../../services/API'
import InputMask from "react-input-mask";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useSweetAlert = makeStyles(sweetAlertStyles);
export default function MembershipForm(props) {

    const sweetAlertClasses = useSweetAlert();
    const [stalert, setAlert] = React.useState(null);
    const value = props.memberObj;
    const { modalRef } = props;

    console.log(props.memberObj.dob);
    // type validation
    const [requiredFirstName, setrequiredFirstName] = React.useState(props.memberObj.fname ? props.memberObj.fname : "");
    const [requiredStateFN, setrequiredStateFN] = React.useState(props.memberObj.fname ? props.memberObj.fname : "");
    const [requiredLastName, setrequiredLastName] = React.useState(props.memberObj.lname ? props.memberObj.lname : "");
    const [requiredStateLN, setrequiredStateLN] = React.useState(props.memberObj.lname ? props.memberObj.lname : "");
    const [requiredDob, setrequiredDob] = React.useState(props.memberObj.dob ? props.memberObj.dob : "");
    const [requiredStateDob, setrequiredStateDob] = React.useState(props.memberObj.dob ? props.memberObj.dob : "");
    const [requiredPName, setrequiredPName] = React.useState(props.memberObj.parentName ? props.memberObj.parentName : "");
    const [requiredStatePName, setrequiredStatePName] = React.useState(props.memberObj.parentName ? props.memberObj.parentName : "");
    const [typeEmail, settypeEmail] = React.useState(props.memberObj.email ? props.memberObj.email : "");
    const [typeEmailState, settypeEmailState] = React.useState(props.memberObj.email ? props.memberObj.email : "");
    const [typePEmail, settypePEmail] = React.useState(props.memberObj.parentEmail ? props.memberObj.parentEmail : "");
    const [typePEmailState, settypePEmailState] = React.useState(props.memberObj.parentEmail ? props.memberObj.parentEmail : "");
    const [contactNo, setcontactNo] = React.useState(props.memberObj.mobileNo ? props.memberObj.mobileNo : "");
    const [contactNoState, setcontactNoState] = React.useState(props.memberObj.mobileNo ? props.memberObj.mobileNo : "");
    const [postal, setpostal] = React.useState(props.memberObj.postal ? props.memberObj.postal : "");
    const [postalState, setpostalState] = React.useState(props.memberObj.postal ? props.memberObj.postal : "");
    const [parentContactNo, setparentContactNo] = React.useState(props.memberObj.parentMobileNo ? props.memberObj.parentMobileNo : "");
    const [parentContactNoState, setparentContactNoState] = React.useState(props.memberObj.parentMobileNo ? props.memberObj.parentMobileNo : "");
    const [simpleSelect, setSimpleSelect] = React.useState(props.memberObj.chapterRecId ? props.memberObj.chapterRecId : "");


    const hideAlert = () => {
        setAlert(null);
    };
    const hideAlertOnSubmit = () => {
        setAlert(null);
        if (modalRef && modalRef.current) {
            modalRef.current.Close();
        }
    }

    let save = () => {

        const data = {
            Fname: requiredFirstName,
            Lname: requiredLastName,
            Dob: requiredDob,
            Email: typeEmail,
            MobileNo: contactNo,
            Postal: postal,
            ChapterRecId: simpleSelect,
            ParentName: requiredPName,
            ParentEmail: typePEmail,
            ParentMobileNo: parentContactNo,
            MemberInfoRecId: 0,
            MemberRecId: 0
        }

        if (Object.keys(props.memberObj).length > 0) {
            data.MemberInfoRecId = props.memberObj.memberInfoRecId;
            data.MemberRecId = props.memberObj.memberRecId;
            let postData = Api.putData(window._ymUrl + "/api/Membership/UpdateMembership", data).then((response) => {
                if (response.succeed) {
                    if (response.value != null) {
                        setAlert(
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Updated!"
                                onConfirm={() => hideAlertOnSubmit()}
                                onCancel={() => hideAlertOnSubmit()}
                                confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
                            >
                                Member Updated successfully..!!
                            </SweetAlert>
                        );
                    }
                    else {
                        setAlert(
                            <SweetAlert
                                info
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Information!"
                                onConfirm={() => hideAlert()}
                                onCancel={() => hideAlert()}
                                confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.info}
                            >
                                Member already Updated..!!
                            </SweetAlert>
                        );
                    }
                }
                else {
                    setAlert(
                        <SweetAlert
                            warning
                            title="Error!"
                            confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.warning}
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                        >
                            Please contact YM Canada. Something went wrong.
                        </SweetAlert>
                    );
                }

                return response.value;
            }).catch((err) => {
                setAlert(
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Warning!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.warning}
                    >
                        Please contact YM Canada. Something went wrong.
                    </SweetAlert>
                );
            });
        }


        else {
            let postData = Api.postData(window._ymUrl + "/api/Membership/CreateMembership", data).then((response) => {
                if (response.succeed) {
                    if (response.value != null) {
                        setAlert(
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Added!"
                                onConfirm={() => hideAlertOnSubmit()}
                                onCancel={() => hideAlertOnSubmit()}
                                confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
                            >
                                Member Added successfully..!!
                            </SweetAlert>
                        );
                    }
                    else {
                        setAlert(
                            <SweetAlert
                                info
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Information!"
                                onConfirm={() => hideAlert()}
                                onCancel={() => hideAlert()}
                                confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.info}
                            >
                                Member already exist..!!
                            </SweetAlert>
                        );
                    }
                }
                else {
                    setAlert(
                        <SweetAlert
                            warning
                            title="Error!"
                            confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.warning}
                            onConfirm={() => hideAlert()}
                            onCancel={() => hideAlert()}
                        >
                            Please contact YM Canada. Something went wrong.
                        </SweetAlert>
                    );
                }

                return response.value;
            }).catch((err) => {
                setAlert(
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Warning!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.warning}
                    >
                        Please contact YM Canada. Something went wrong.
                    </SweetAlert>
                );
            });
        }

    }

    const handleSimple = (event) => {
        setSimpleSelect(event.target.value);
    };

    const verifyAge = (value) => {
        let userdob = new Date(value);
        var diff = (userdob.getTime() - Date.now()) / 1000;
        diff /= (60 * 60 * 24);
        let age = Math.abs(diff / 365.25);

        if (age >= 13 && age <= 17) {
            return true;
        }

        return false;
    }



    const verifyLengthAndAge = (value, length) => {
        if (value.length >= length && verifyAge(value)) {
            return true;
        }

        return false;
    };

    const verifyEmail = (value) => {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    };
    // function that verifies if a string has a given length or not
    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };
    // function that verifies if value contains only numbers
    const verifyContact = (value) => {
        var numberRex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };

    const verifyParentContact = (value) => {
        var numberRex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };

    const verifyPostalCode = (value) => {
        var postalCodeRegex = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i);
        if (postalCodeRegex.test(value)) {
            return true;
        }
        return false;
    };

    // verifies if value is a valid URL
    const verifyUrl = (value) => {
        try {
            new URL(value);
            return true;
        } catch (_) {
            return false;
        }
    };

    const typeClick = () => {
        let isSuccess = true
        
        if (requiredStateFN === "" || requiredStateFN === "error") {
            setrequiredStateFN("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    First Name is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (requiredStateLN === "" || requiredStateLN === "error") {
            setrequiredStateLN("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Last Name is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (typeEmailState === "" || typeEmailState === "error") {
            settypeEmailState("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Email is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (requiredStateDob === "" || requiredStateDob === "error") {
            setrequiredStateDob("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Age must be in between 13 - 17 years.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (contactNoState === "" || contactNoState === "error") {
            setcontactNoState("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Contact No. is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (postalState === "" || postalState === "error") {
            setpostalState("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Postal Code is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (simpleSelect === "" || simpleSelect === "error") {
            setSimpleSelect("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Chapter is required.
                </SweetAlert>
            );
            return;
        }
        if (requiredStatePName === "" || requiredStatePName === "error") {
            setrequiredStatePName("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Parent Name is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (typePEmailState === "" || typePEmailState === "error") {
            settypePEmailState("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Parent Email is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (parentContactNoState === "" || parentContactNoState === "error") {
            setparentContactNoState("error");
            setAlert(
                <SweetAlert
                    danger
                    title="Error!"
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                    onConfirm={() => hideAlert()}
                    onCancel={() => hideAlert()}
                >
                    Parent Contact No. is required.
                </SweetAlert>
            );
            isSuccess = false;
            return;
        }
        if (isSuccess) {
            save();
        }
    };

    const classes = useStyles();
    return (
        <GridContainer style={{ marginTop: "-37px", marginBottom: "-20px" }}>
            <GridItem xs={12} sm={12} md={12} style={{ width: "700px" }}>
                <Card>
                    {/* <CardHeader color="info" text>
                        <CardText color="info">
                            <h4 className={classes.cardTitle}>Membership Form</h4>
                        </CardText>
                    </CardHeader> */}
                    <CardBody>
                        <form style={{ marginLeft: "50px" }}>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        First Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={requiredStateFN === "success"}
                                        error={requiredStateFN === "error"}
                                        id="requiredFirstName"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyLength(event.target.value, 0)) {
                                                    setrequiredStateFN("success");
                                                } else {
                                                    setrequiredStateFN("error");
                                                }
                                                setrequiredFirstName(event.target.value);
                                            },
                                            value: requiredFirstName,
                                            type: "text",
                                            placeholder: "Please Enter First Name",
                                            reference: "Fname",
                                            endAdornment:
                                                requiredStateFN === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Last Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={requiredStateLN === "success"}
                                        error={requiredStateLN === "error"}
                                        id="requiredLastName"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyLength(event.target.value, 0)) {
                                                    setrequiredStateLN("success");
                                                } else {
                                                    setrequiredStateLN("error");
                                                }
                                                setrequiredLastName(event.target.value);
                                            },
                                            value: requiredLastName,
                                            type: "text",
                                            placeholder: "Please Enter Last Name",
                                            reference: "Lname",
                                            endAdornment:
                                                requiredStateLN === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Email
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={typeEmailState === "success"}
                                        error={typeEmailState === "error"}
                                        id="typeemail"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyEmail(event.target.value)) {
                                                    settypeEmailState("success");
                                                } else {
                                                    settypeEmailState("error");
                                                }
                                                settypeEmail(event.target.value);
                                            },
                                            value: typeEmail,
                                            type: "email",
                                            placeholder: "Please Enter Email",
                                            reference: "Email",
                                            endAdornment:
                                                typeEmailState === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Date Of Birth
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={requiredStateDob === "success"}
                                        error={requiredStateDob === "error"}
                                        id="required"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyLengthAndAge(event.target.value, 0)) {
                                                    setrequiredStateDob("success");
                                                } else {
                                                    setrequiredStateDob("error");
                                                }
                                                setrequiredDob(event.target.value);
                                            },
                                            value: requiredDob,
                                            type: "date",
                                            min: "2019-01-24",
                                            max: "2020-05-31",
                                            reference: "Dob",
                                            endAdornment:
                                                requiredStateDob === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <span style={{ marginLeft: "101px", fontSize: "12px", color: "darkgray", fontStyle: "italic" }}>Age must be in between 13 - 17 years</span>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Contact No
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>

                                    <CustomInputWithMask
                                        mask="+1 999 999-9999"
                                        maskPlaceholder="Enter Mobile"
                                        value={contactNo}
                                        inputmaskprops={{
                                            onChange: (event) => {
                                                if (verifyContact(event.target.value)) {
                                                    setcontactNoState("success");
                                                } else {
                                                    setcontactNoState("error");
                                                }
                                                setcontactNo(event.target.value);
                                            }
                                        }}
                                        success={contactNoState === "success"}
                                        error={contactNoState === "error"}
                                        id="number"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: "text",
                                            placeholder: "+1 999 999-9999",
                                            reference: "MobileNo",
                                            endAdornment:
                                                contactNoState === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Postal Code
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>

                                    <CustomInputWithMask
                                        mask="a9a 9a9"
                                        value={postal}
                                        inputmaskprops={{
                                            onChange: (event) => {
                                                if (verifyPostalCode(event.target.value)) {
                                                    setpostalState("success");
                                                } else {
                                                    setpostalState("error");
                                                }
                                                setpostal(event.target.value);
                                            }
                                        }}
                                        success={postalState === "success"}
                                        error={postalState === "error"}
                                        id="postal"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: "text",
                                            placeholder: "A1A A1A",
                                            reference: "Postal",
                                            endAdornment:
                                                postalState === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Chapter
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <FormControl
                                        fullWidth
                                        // className={classes.selectFormControl}
                                        style={{ marginTop: "10px" }}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            className={classes.selectLabel}
                                        >
                                            Choose Chapter
                                        </InputLabel>
                                        <Select //ref={ChapterRecId}
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={simpleSelect}
                                            onChange={handleSimple}
                                            inputProps={{
                                                name: "simpleSelect",
                                                id: "simple-select",
                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                            >
                                                Choose City
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="1"
                                            >
                                                Mississauga
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="2"
                                            >
                                                Calgary
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="3"
                                            >
                                                Milton
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="4"
                                            >
                                                Winnipeg
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="5"
                                            >
                                                Durham

                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Parent Name
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={requiredStatePName === "success"}
                                        error={requiredStatePName === "error"}
                                        id="required"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyLength(event.target.value, 0)) {
                                                    setrequiredStatePName("success");
                                                } else {
                                                    setrequiredStatePName("error");
                                                }
                                                setrequiredPName(event.target.value);
                                            },
                                            value: requiredPName,
                                            type: "text",
                                            placeholder: "Please Enter Parent Name",
                                            reference: "ParentName",
                                            endAdornment:
                                                requiredStatePName === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Parent Email
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>
                                    <CustomInput
                                        success={typePEmailState === "success"}
                                        error={typePEmailState === "error"}
                                        id="typepemail"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => {
                                                if (verifyEmail(event.target.value)) {
                                                    settypePEmailState("success");
                                                } else {
                                                    settypePEmailState("error");
                                                }
                                                settypePEmail(event.target.value);
                                            },
                                            value: typePEmail,
                                            type: "email",
                                            placeholder: "Please Enter Parent Email",
                                            reference: "ParentEmail",
                                            endAdornment:
                                                typePEmailState === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={2}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Parent Contact No
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={12} sm={7}>

                                    <CustomInputWithMask
                                        mask="+1 999 999-9999"
                                        value={parentContactNo}
                                        inputmaskprops={{
                                            onChange: (event) => {
                                                if (verifyParentContact(event.target.value)) {
                                                    setparentContactNoState("success");
                                                } else {
                                                    setparentContactNoState("error");
                                                }
                                                setparentContactNo(event.target.value);
                                            }
                                        }}
                                        success={parentContactNoState === "success"}
                                        error={parentContactNoState === "error"}
                                        id="number"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            // onChange: (event) => {
                                            //     if (verifyNumber(event.target.value)) {
                                            //     setnumberState("success");
                                            //     } else {
                                            //     setnumberState("error");
                                            //     }
                                            //     setnumber(event.target.value);
                                            // },
                                            type: "text",
                                            placeholder: "+1 999 999-9999",
                                            reference: "ParentMobileNo",
                                            endAdornment:
                                                parentContactNoState === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : undefined,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3}>
                                    <FormLabel className={classes.labelLeftHorizontal}>
                                        <code>*</code>
                                    </FormLabel>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button color="rose" onClick={typeClick}>
                            Submit
                        </Button>
                    </CardFooter>
                </Card>
            </GridItem>
            {stalert}
        </GridContainer>
    );
}