
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import AuthLayout from "layouts/Auth.js";
// import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import store from 'redux/store.js'




import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
//window._ymUrl = "https://adminportal.ymmississauga.ca"; //for prod
window._ymUrl = "https://adminuatdev.fourmatics.ca";      //test after deploy
//window._ymUrl = "https://localhost:44375";              //for local test

function App() {


  return (<BrowserRouter>
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route exact path="/">
        <Redirect to="/auth/login-page" />
      </Route>

      {/* <Route exact path="/" component={AuthLayout} /> */}
      <Route path="/admin" component={AdminLayout} />
    </Switch>
  </BrowserRouter>)
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  ,
  document.getElementById("root")
);
