import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@mui/material/IconButton';
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

const YMModal = forwardRef((props, ref) => {

    const { ModalTitle, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();
    //setShow(openPopup);
    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    //const handleClose = () => setOpenPopup(false);

    useImperativeHandle(ref, () => ({
        Close: () => {
            handleClose();
        },
        Show: () => {
            handleShow();
        }


    }));

    return (
        <>

            <Dialog open={show} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
                <DialogTitle className={classes.dialogTitle} style={{ backgroundColor: "#204da1", color: "white", borderRadius: "15px", marginBottom: "5px" }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            {ModalTitle}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers style={{ backgroundColor: 'white' }}>
                    {children}
                </DialogContent>
                {/* <Button color="rose" >
                        {ModalBtnSubmit}
                    </Button> */}


            </Dialog>

        </>
    )
})
export default YMModal;