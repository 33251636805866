import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { dataTable } from "variables/general.js";
import { useRenderMembershipData } from "variables/MembershipList.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Add from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { YMModal } from "components/Modal/Index.js";
import MembershipForm from "./MembershipForm";
import * as Api from "services/API"
import EmailForm from "../Email/EmailForm";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const customStyles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
};

const useStyles = makeStyles(customStyles);
const useSweetAlert = makeStyles(styles);
export default function Membership() {
    const sweetAlertClasses = useSweetAlert();
    // const { list, loading } = useRenderMembershipData();
    const [stalert, setAlert] = React.useState(null);
    const [memberObj, setMemberObj] = React.useState({});
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [list, setDataMemberList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [isComponentLoaded, setComponentLoaded] = React.useState();
    const [data, setData] = React.useState();


    const reacTableRef = React.useRef(null);
    const modalRef = React.useRef(null);
    const modalRefEmail = React.useRef(null);

    const loadList = React.useCallback(async () => {
        let _rec = await Api.getData(window._ymUrl + "/api/Membership/GetAll").then((response) => {
            console.log(response.value);
            return response.value;
        }).catch((err) => {
            console.log(err);
        });
        setDataMemberList(_rec);
        setLoading(true);
    })
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log("Refrech  in first=>", refreshCount);
            loadList();
        }
        return () => mounted = false;
    }, [refreshCount]);
    const sweetAlert = (id) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => successDelete(id)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
                cancelBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover the Member!
            </SweetAlert>
        );
    }
    const successDelete = (id) => {
        const memberId = {
            MemberRecId: id
        }
        let data = Api.deleteData(window._ymUrl + "/api/Membership/DeleteMembership", memberId).then((response) => {
            console.log(response.value);
            setAlert(
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Deleted!"
                    onConfirm={() => hideAlertOnSuccess()}
                    onCancel={() => hideAlert()}
                    confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
                >
                    Member has been deleted.
                </SweetAlert>
            );

        }).catch((err) => {
            console.log(err);
        });


    };

    const refreshGrid = () => {
        setRefreshCount(prevState => prevState + 1);
    }

    const hideAlert = () => {

        setAlert(null);
    };

    const hideAlertOnSuccess = () => {
        refreshGrid();
        setAlert(null);
    }

    const YYYYMMDD = (date) => {
        let _date = new Date(date);

        return _date.getFullYear() +
            "-" + ("0" + (_date.getMonth() + 1)).slice(-2) +
            "-" + ("0" + (_date.getDate())).slice(-2);
    }



    useEffect(() => {
        if (!isComponentLoaded) {
            setComponentLoaded(true);
        } else if (isComponentLoaded && loading) {
            console.log("Refrech =>", refreshCount);
            let _list = list ? list : [];
            setData(
                _list.map((prop, key) => {

                    return {
                        id: prop.memberRecId,
                        fname: prop.fname,
                        lname: prop.lname,
                        email: prop.email,
                        dob: YYYYMMDD(prop.dob),
                        mobileno: prop.mobileNo,
                        postal: prop.postal,
                        chapter: prop.chapter,
                        parentname: prop.parentName,
                        parentemail: prop.parentEmail,
                        parentmobileno: prop.parentMobileNo,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">

                                {/* use this button to add a edit kind of action */}
                                <Tooltip title="Edit" TransitionComponent={Zoom}>
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            let obj = list.find((o) => o.memberRecId === prop.memberRecId);
                                            obj.dob = YYYYMMDD(obj.dob);

                                            if (modalRef && modalRef.current) {
                                                modalRef.current.Show();
                                            }
                                            setMemberObj(obj);
                                        }}
                                        color="warning"
                                        className="edit"
                                    >
                                        <Dvr />
                                    </Button>
                                </Tooltip>{" "}
                                {/* use this button to remove the data row */}
                                <Tooltip title="Delete" TransitionComponent={Zoom}>
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                            var newData = list;

                                            newData.find((o => {
                                                if (o.memberRecId === prop.memberRecId) {
                                                    // here you should add some custom code so you can delete the data
                                                    // from this component and from your server as well
                                                    //newData.splice(i, 1);

                                                    { sweetAlert(o.memberRecId) }

                                                    return true;
                                                }
                                                return false;
                                            }));
                                            //setData([...newData]);
                                        }}
                                        color="danger"
                                        className="remove"
                                    >
                                        <Close />
                                    </Button>
                                </Tooltip>{" "}
                            </div>
                        ),
                    };
                })
            );
        }
    }, [isComponentLoaded, loading == true, refreshCount])

    const classes = useStyles();
    return (
        <>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>


                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Tooltip title="Refresh" TransitionComponent={Zoom}>
                                    <Button color="rose" justIcon onClick={() => {

                                        refreshGrid();
                                    }}><RefreshIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Add Member" TransitionComponent={Zoom}>
                                    <Button color="rose" justIcon onClick={() => {
                                        if (modalRef && modalRef.current) {
                                            modalRef.current.Show();
                                        }
                                        setMemberObj({});
                                    }}><Add /></Button>
                                </Tooltip>
                                <Tooltip title="Email" TransitionComponent={Zoom}>
                                    <Button color="rose" justIcon onClick={() => {

                                        if (modalRefEmail && modalRefEmail.current) {
                                            modalRefEmail.current.Show();
                                        }
                                    }}><EmailIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Export" TransitionComponent={Zoom}>
                                    <Button tooltip="Export" color="rose" justIcon onClick={() => {
                                        let data = Api.getData(window._ymUrl + "/api/Report/GetMembershipListReport").then((response) => {
                                            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                                            const fileExtension = '.xlsx';

                                            const ws = XLSX.utils.json_to_sheet(response.value);
                                            ws.A1.v = "First Name";
                                            ws.B1.v = "Last Name";
                                            ws.C1.v = "Email Address";
                                            ws.D1.v = "Date of Birth";
                                            ws.E1.v = "Contact No.";
                                            ws.F1.v = "Parent Name";
                                            ws.G1.v = "Parent Email Address";
                                            ws.H1.v = "Parent Contact No.";
                                            ws.I1.v = "Postal Code";
                                            ws.J1.v = "City";
                                            ws.K1.v = "Chapter";

                                            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                                            const data = new Blob([excelBuffer], { type: fileType });
                                            FileSaver.saveAs(data, "Membership List" + fileExtension);

                                        }).catch((err) => {
                                            console.log(err);
                                        });


                                    }}><FileDownloadIcon /></Button>
                                </Tooltip>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                ref={reacTableRef}
                                columns={[
                                    {
                                        Header: "First Name",
                                        accessor: "fname",
                                    },
                                    {
                                        Header: "Last Name",
                                        accessor: "lname",
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                    },
                                    {
                                        Header: "DOB",
                                        accessor: "dob",
                                        show: false
                                    },
                                    {
                                        Header: "Mobile",
                                        accessor: "mobileno",
                                    },
                                    {
                                        Header: "Postal",
                                        accessor: "postal",
                                        show: false
                                    },
                                    {
                                        Header: "Chapter",
                                        accessor: "chapter",
                                        show: false
                                    },
                                    {
                                        Header: "Parent Name",
                                        accessor: "parentname",
                                    },
                                    {
                                        Header: "Parent Email",
                                        accessor: "parentemail",
                                        show: false
                                    },
                                    {
                                        Header: "Parent Mobile",
                                        accessor: "parentmobileno",
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions",
                                    },
                                ]}
                                data={data && data.length > 0 ? data : []}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                {stalert}
            </GridContainer>
            <YMModal
                ref={modalRef}
                ModalTitle={"Membership Form"}
                ModalBtnSubmit={"Add"}
            >
                <MembershipForm memberObj={memberObj} modalRef={modalRef} />
            </YMModal>
            <YMModal
                ref={modalRefEmail}
                ModalTitle={"Email Member"}
                ModalBtnSubmit={"Send"}
            >
                <EmailForm modalRefEmail={modalRefEmail} reacTableRef={reacTableRef} />
            </YMModal>
        </>
    );
}
