import { useCallback, useState, useEffect } from "react";
import * as Api from "services/API";
export const useRenderMembershipData = () => {
    const [list, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const loadList = useCallback(async () => {
        let data = await Api.getData(window._ymUrl + "/api/Membership/GetAll").then((response) => {
            console.log(response.value);
            return response.value;
        }).catch((err) => {
            console.log(err);
        });
        setData(data);
        setLoading(true);
    })
    useEffect(() => {
        loadList();
    }, []);
    return { list, loading };
}
